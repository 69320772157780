<template>
  <div class="font-weight-bold">
    <b-icon :class="value == min ? 'disabled' : ''" icon="caret-left" @click="decrease()" />
    <span class="user-select-none"> {{ value }} %</span>
    <b-icon icon="caret-right" @click="increase()" />
  </div>
</template>
<script>
export default {
  name: 'NumberStepper',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: Number,
      default: undefined,
    },
    min: {
      type: Number,
      default: undefined,
    },
    step: {
      type: Number,
      default: undefined,
    },
  },

  methods: {
    decrease() {
      let res = this.value - this.step;
      if (res >= this.min) this.$emit('input', res);
    },
    increase() {
      let res = this.value + this.step;
      this.$emit('input', res);
    },
  },
};
</script>
<style scoped>
.disabled {
  opacity: 0.25;
}
</style>
