<template>
  <div>
    <b-modal id="bid-confirm" ref="bid-confirm" size="md" :title="$t('auction.increaseConfirmTitle')" @ok="increase">
      {{ $t('auction.increaseConfirmText') }}
    </b-modal>
  </div>
</template>

<script>
import errorsHandler from '@/utils/errorsHandler';
export default {
  name: 'ModalBidConfirm',
  methods: {
    async increase() {
      this.$refs['bid-confirm'].hide();
      this.$emit('increaseBidConfirm');
    },
  },
};
</script>
