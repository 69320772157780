<template>
  <!-- REPLACE SPOT PANEL -->
  <b-sidebar
    id="sidebar-replace-spot"
    :title="$t('booking.replaceSpot')"
    :bg-variant="isThemeHeader === 'true' ? 'dark' : 'light'"
    shadow
    backdrop
    header-class="px-4"
    lazy
    right
    :z-index="1100"
    @shown="loadData()"
    @hidden="clearFields()"
  >
    <div class="px-4 py-2">
      <SpinnerLoader v-if="isSidebarLoading" :loading="isSidebarLoading === true ? 'loading' : 'success'" />
      <template v-else>
        <p class="font-weight-bold">{{ $t('booking.selectCommercial') }}</p>
        <b-table
          v-if="mediaPlanInfo && commercialItems"
          ref="mediaPlanCommercialsTable"
          :fields="[
            { key: 'name', label: $t('table.name') },
            { key: 'duration', label: $t('table.durationShort') },
            { key: 'version', label: $t('table.version') },
          ]"
          :items="commercialItems"
          select-mode="single"
          selectable
          responsive
          hover
          show-empty
          class="table-sm small commercials-table"
          :table-class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'"
          @row-selected="onCommRowSelected"
        >
          <template #head(name)="data">
            <div class="resizable-div__name">
              {{ data.label }}
            </div>
          </template>
          <template #cell(name)="data">
            <span :class="[data.item.double_spot_id ? 'ds-option' : '', data.item.couple ? 'couple' : '']">
              [{{ data.item.id }}] {{ data.item.name }}
            </span>
          </template>
        </b-table>
        <b-button :disabled="isBusy || !(selectedCommercial && mediaPlanInfo)" @click="replaceSpot()">
          {{ $t('booking.replaceSpot') }}
        </b-button>
        <b-button id="sidebar-replace-spot__close-btn" v-b-toggle.sidebar-replace-spot class="d-none">Toggle Sidebar</b-button>
      </template>
    </div>
  </b-sidebar>
</template>

<script>
import { mapGetters } from 'vuex';
import errorsHandler from '@/utils/errorsHandler';
import { BTable, BSidebar, VBToggle } from 'bootstrap-vue';
import SpinnerLoader from '@/components/SpinnerLoader';
import { getMediaPlansId, getMediaPlans, getOrdersId, getOrders, getProjects } from '@/api/apiServices';

export default {
  name: 'ModalReplaceSpot',
  components: { BTable, BSidebar, SpinnerLoader },
  directives: { 'b-toggle': VBToggle },
  props: {
    selectedSpot: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isSidebarLoading: true,
      commercialItems: [],
      selectedCommercial: '',
      isBusy: false,
      mediaPlanInfo: '',
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      commercialVersionTypes: 'getCommercialVersionTypes',
    }),
  },
  methods: {
    //separate API func to not override POM filters data in Booking
    async GET_MEDIA_PLANS_ID(payload) {
      await getMediaPlansId(payload.id, payload.data)
        .then((res) => (this.mediaPlanInfo = res.data?.data))
        .catch((e) => {});
    },

    async loadData() {
      await Promise.all([
        !this.commercialVersionTypes?.data ? this.$store.dispatch('GET_COMM_VER_TYPES', { per_page: 1000 }) : undefined,
        this.GET_MEDIA_PLANS_ID({ id: this.selectedSpot.mediaplan_id, data: { include: 'commercials' } }),
      ]);
      this.loadCommercialsForTable();
      this.isSidebarLoading = false;
    },

    onCommRowSelected(items) {
      this.selectedCommercial = items[0] || '';
      this.commercialItems.forEach((el) => {
        if (el.double_spot_id) {
          if (this.selectedCommercial && el.double_spot_id === this.selectedCommercial.double_spot_id) {
            el.couple = 'couple';
          } else {
            delete el.couple;
          }
        }
      });
    },

    loadCommercialsForTable() {
      this.commercialItems = [];
      this.mediaPlanInfo?.commercials.forEach((el) => {
        this.commercialItems.push({
          id: el.id,
          name: el.name,
          duration: el.duration,
          version: this.commercialVersionTypes.data.find((type) => type.id === el.commercial_version_type_id)?.name,
          distance: el.distance,
          double_spot_id: el.double_spot_id,
        });
      });
    },

    async replaceSpot() {
      this.isBusy = true;
      await this.$store.dispatch('POST_SWAP_SPOT', {
        formData: {
          spot_id: this.selectedSpot.id,
          commercial_id: this.selectedCommercial.id,
          mediaplan_id: this.mediaPlanInfo.id,
        },
        handler: (res) => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.spotReplaced'),
          });
          this.selectedCommercial = '';
          this.$emit('update');
          document.getElementById('sidebar-replace-spot__close-btn')?.click();
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
      this.isBusy = false;
    },

    clearFields() {
      this.isSidebarLoading = true;
      this.selectedCommercial = '';
      this.commercialItems = [];
      this.mediaPlanInfo = '';
    },
  },
};
</script>

<style lang="sass" src="../../assets/sass/bookingCommercialsTable.sass"></style>

<style lang="sass">
#sidebar-replace-spot.b-sidebar
  width: 450px
</style>
