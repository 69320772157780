import { mapGetters } from 'vuex';

const showBlockSecondParam = {
  computed: {
    ...mapGetters({
      placementType: 'getPlacementType',
    }),
  },
  methods: {
    //show second block number based on type of optionally selected MP  or total spot duration
    showBlockSecondParam(block, mediaPlan) {
      switch (this.secondBlockParam) {
        case 1:
          return block.spot_total_duration;
        case 2:
          if (mediaPlan?.placement_type_id) {
            // !!! Make sure that page loads placement types list before running this code
            const MPPlType = this.placementType?.find((p) => p.id === mediaPlan.placement_type_id);
            if (!MPPlType) return block.free_duration;
            switch (MPPlType.name) {
              case 'GRP fixed':
                return block.pullable_free_duration;
              case 'Time fixed':
                return block.pullable_free_duration;
              case 'Automatic':
                return block.low_priority_free_duration;
              case 'Low priority':
                return block.free_duration;
              case 'Cross-channel':
                return block.free_duration;
              default:
                return block.free_duration;
            }
          } else return block.free_duration;
      }
    },
  },
};

export default showBlockSecondParam;
