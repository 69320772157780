const showDoubleSpots = {
  data() {
    return {
      doubleSpotsCount: 0,
      usedDsId: [],
    };
  },
  methods: {
    showDoubleSpots(block, rowHeight, innerDivHeight) {
      if (block?.spots) {
        block.spots.forEach((spot, index) => {
          if (spot.double_spot_id) {
            const firstDouble = block.spots.find((el) => el.double_spot_id === spot.double_spot_id && !this.usedDsId.includes(el.id || el.spot_id));
            const firstDoubleId = firstDouble ? firstDouble.id || firstDouble.spot_id : null;
            if ((spot.id || spot.spot_id) === firstDoubleId) {
              this.usedDsId.push(spot.id || spot.spot_id);
              const secOfPair = block.spots.find((el) => el.double_spot_id === spot.double_spot_id && !this.usedDsId.includes(el.id || el.spot_id));
              if (secOfPair) this.usedDsId.push(secOfPair.id || secOfPair.spot_id);
              const secIndex = block.spots.indexOf(secOfPair);
              block.spots[index].height = (secIndex - index) * rowHeight + innerDivHeight;
              block.spots[index].left = this.doubleSpotsCount > 0 ? this.doubleSpotsCount * 6 : 0;
              this.doubleSpotsCount++; // for space between ds lines
            }
          }
        });
      }
    },
  },
};

export default showDoubleSpots;
