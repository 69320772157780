const getBlockRatings = {
  methods: {
    //Get block rating based on T.A. filter
    getBlockRating(grps, minGRP, measurements, target) {
      if (grps?.length > 0 && measurements && target) {
        const data = grps.find((el) => el.target_audience_id === target.id);
        return data ? data.grp : minGRP ?? 0;
      } else {
        return minGRP ?? 0;
      }
    },

    getBlockFactRating(fact_grps, measurements, target) {
      if (fact_grps?.length > 0 && measurements && target) {
        const data = fact_grps.find((el) => el.target_audience_id === target.id);
        return data ? data.grp_average : '0';
      } else {
        return '0';
      }
    },
  },
};

export default getBlockRatings;
