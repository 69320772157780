import { mapGetters } from 'vuex';
const blockSecondParam = localStorage.getItem('blockSecondParam');
const placementGrid = {
  data() {
    return {
      gridWithEmpty: {},
      countUsedTime: {},
      max_scale: 0,
      posY: 0,
      clientY: 0,
      scaleHour: {},

      count: 0,
      firstDay: '',
      firstDays: '',
      lastDays: '',
      lastDay: '',

      yearsOfGrid: '',
      prevYearsOfGrid: '', // used to compare with current one for changes

      showSmallGrid: false,
      block_height: 82,
      current_scale: 200,
      program_height: 42,

      secondBlockParam: blockSecondParam === '1' || blockSecondParam === '2' ? +blockSecondParam : 1,
    };
  },
  computed: {
    ...mapGetters({
      BROADCAST_START_TIME_IN_SEC: 'getBroadcastStartInterval',
      gridBroadcastingSettings: 'getGridBroadcastingSettings',
      isCurrentUserSH: 'getIsCurrentUserSaleshouseManager',
      isCurrentUserAMA: 'getIsCurrentUserAgencyManager',
    }),
    gridTimeLimits() {
      const data = this.broadcastData || this.booking || this.marketingGrid;
      let broadcast_intervals = Object.values(data.data.broadcast_intervals);
      let starts = broadcast_intervals.map((x) => x.start ?? Infinity);
      let ends = broadcast_intervals.map((x) => x.end ?? 0);
      let broadcast_start = Math.min(...starts);
      let broadcast_end = Math.max(...ends);
      return { start: broadcast_start, end: broadcast_end };
    },
    grpByDays() {
      if (this.broadcastData) return this.broadcastData?.data?.default_plan_grps || {};
      else if (this.booking) return this.booking?.data?.default_plan_grps || {};
      else if (this.marketingGrid) return this.marketingGrid?.data?.default_plan_grps || {};
      else return {};
    },
    weekDays() {
      return [
        this.$t('table.sun'),
        this.$t('table.mon'),
        this.$t('table.tue'),
        this.$t('table.wed'),
        this.$t('table.thu'),
        this.$t('table.fri'),
        this.$t('table.sat'),
      ];
    },
  },
  destroyed() {
    this.$store.commit('clearGridBroadcastingSettings');
  },
  methods: {
    sec2timeSimple(sec) {
      return ('00' + Math.floor(sec / 3600)).slice(-2) + ':' + ('00' + (Math.floor(sec / 60) % 60)).slice(-2) + ':' + '00';
    },
    sec2time(sec) {
      return (
        ('00' + Math.floor(sec / 3600)).slice(-2) + ':' + ('00' + (Math.floor(sec / 60) % 60)).slice(-2) + ':' + ('00' + (Math.floor(sec / 1) % 60)).slice(-2)
      );
    },
    px2sec(px) {
      return Math.round((px / this.max_scale) * 3600);
    },
    time2sec(time) {
      return parseInt(time.substring(6, 8)) + 60 * parseInt(time.substring(3, 5)) + 3600 * parseInt(time.substring(0, 2));
    },
    timeOfClick(posY) {
      return this.sec2timeSimple(this.px2sec(posY) + this.gridTimeLimits.start);
    },
    sec2px(sec) {
      return Math.round((sec / 3600) * this.max_scale);
    },

    getPosY(event) {
      // set coordinate Y of click
      const tableScrollDiv = document.getElementById('table-broadcast-grid');
      const offsetpos = this.recursive_offset(tableScrollDiv);
      this.clientY = event.clientY;
      this.posY = event.clientY + offsetpos.y;
    },
    recursive_offset(aobj) {
      // get coordinate X & Y of click in scrollable element
      let currOffset = {
        y: 0,
        x: 0,
      };
      if (aobj.scrollTop) {
        currOffset.y = aobj.scrollTop;
      }
      if (aobj.offsetTop) {
        currOffset.y -= aobj.offsetTop;
      }

      if (aobj.scrollLeft) {
        currOffset.x = aobj.scrollLeft;
      }
      if (aobj.offsetLeft) {
        currOffset.x -= aobj.offsetLeft;
      }
      return currOffset;
    },

    setBlockBackground(duration, used) {
      if (used) {
        const percent = used / duration;
        if (percent >= 0.5 && percent < 0.9) {
          return 'block-bg-yellow';
        } else if (percent >= 0.9) {
          return 'block-bg-red';
        } else {
          return '';
        }
      } else {
        return '';
      }
    },

    setBlockBorder(type) {
      if (type == 2) return 'border-auction';
      else {
        return '';
      }
    },

    async buildGrid(updated_block) {
      const data = this.broadcastData || this.booking || this.marketingGrid;
      if (!data) return;
      this.$store.commit('setGridBroadcastSettings', data.data.settings); // set in store to use between components
      const grid = data.data.grid;
      const counts = data.data.count;

      let countTimeInDay = {};
      let blocks = [];
      let programs = [];

      // calc. count of programs & blocks
      for (let day in counts) {
        for (let hour in counts[day]) {
          if (!blocks[hour]) blocks[hour] = [];
          blocks[hour][day] = counts[day][hour]['blocks'];
          if (!programs[hour]) programs[hour] = [];
          programs[hour][day] = counts[day][hour]['program_releases'];
        }
      }

      // now: calc the scale by hours
      let scale = {};
      this.max_scale = 0;

      for (let hour in programs) {
        let sizes = [];
        let i = 0;
        for (let day in programs[hour]) {
          sizes[i++] = blocks[hour][day] * this.block_height + programs[hour][day] * this.program_height;
        }
        this.$set(scale, hour, Math.max(this.current_scale, Math.max(...sizes)));
        if (scale[hour] > this.max_scale) this.max_scale = scale[hour];
      }

      let broadcast_intervals = Object.values(data.data.broadcast_intervals);
      let starts = broadcast_intervals.map((x) => x.start ?? Infinity);
      //  let ends = broadcast_intervals.map((x) => x.start);
      let broadcast_start = Math.min(...starts);
      //   let broadcast_end = Math.max(...ends);
      console.log(broadcast_start);

      // render the grid:
      // 2) programs & blocks
      // let last_time = process.env.VUE_APP_BROADCAST_START_TIME ? +process.env.VUE_APP_BROADCAST_START_TIME : 6 * 60 * 60;
      let last_time = broadcast_start;

      for (let day in grid) {
        // start day from .env variable or 6 am
        // last_time = process.env.VUE_APP_BROADCAST_START_TIME ? +process.env.VUE_APP_BROADCAST_START_TIME : 6 * 60 * 60;
        last_time = broadcast_start;
        countTimeInDay[day] = { total: 0, used: 0 };

        // render programs
        for (let prog of grid[day]) {
          let prog_start_at = prog.program_release_start_interval ? prog.program_release_start_interval : prog.blocks[0].block_start_interval;
          let prog_end_at = prog.program_release_end_interval ? prog.program_release_end_interval : prog.blocks[0].block_end_interval;

          if (prog_start_at > last_time) {
            prog.marginTop = this.sec2px(prog_start_at - last_time);
          }

          // start program
          let program_header_height = prog.program_release_program_name === null ? 0 : this.program_height; // independent block ? or * 4
          last_time = Math.max(last_time + this.px2sec(program_header_height), prog_start_at + this.px2sec(program_header_height));

          // blocks
          prog.blocks.forEach((block, index, arr) => {
            if (typeof updated_block !== 'undefined' && updated_block.block_id === block.block_id) {
              this.$set(arr, index, (block = { ...block, ...updated_block }));
            }
            let block_start_at = block.block_start_interval;

            if (block_start_at > last_time) {
              block.marginTop = this.sec2px(block_start_at - last_time);
            }

            //add total&used block seconds in a day
            countTimeInDay[day].total += block.block_duration;
            if (block.spot_total_duration) countTimeInDay[day].used += block.spot_total_duration;

            // block itself
            last_time = Math.max(last_time + this.px2sec(this.block_height), block_start_at + this.px2sec(this.block_height)); // !!! ATTN !!!
          });
          // finalize program
          if (prog_end_at > last_time) {
            prog.paddingBottom = this.sec2px(prog_end_at - last_time);
          }
          last_time = Math.max(last_time, prog_end_at);
        }
      }
      this.gridWithEmpty = grid;
      this.countUsedTime = countTimeInDay;
      this.scaleHour = scale;

      // Sort the keys of scaleHour
      this.scaleHour = Object.keys(this.scaleHour).sort();
    },

    //Set count and starting dates for arrows
    setCountAndDates(used) {
      const usedDate = used === 'day' ? new Date(this.selects.day) : new Date();
      const todayDate = new Date();
      const SECONDS_IN_DAY = 86400000;
      const START_WEEK_DAY_INDEX = process?.env?.VUE_APP_START_WEEK_DAY ? +process.env.VUE_APP_START_WEEK_DAY : 1;
      let firstDayOfWeek = null;
      //Get first day of week for today day
      let currentWeekStartIndex = null;
      if (todayDate.getDay() >= START_WEEK_DAY_INDEX) {
        currentWeekStartIndex = todayDate.getDate() - todayDate.getDay() + START_WEEK_DAY_INDEX;
      } else {
        currentWeekStartIndex = todayDate.getDate() - todayDate.getDay() - 7 + START_WEEK_DAY_INDEX;
      }

      //if datepicker was used last
      if (used === 'day') {
        if (usedDate.getUTCDay() >= START_WEEK_DAY_INDEX) {
          firstDayOfWeek = usedDate.getUTCDate() - usedDate.getUTCDay() + START_WEEK_DAY_INDEX;
        } else {
          firstDayOfWeek = usedDate.getUTCDate() - usedDate.getUTCDay() - 7 + START_WEEK_DAY_INDEX;
        }

        let firstDayOfCurrent = new Date(todayDate.getUTCFullYear(), todayDate.getUTCMonth(), currentWeekStartIndex);
        //Set first & last days of selected week from datepicker
        this.firstDays = new Date(Date.UTC(usedDate.getUTCFullYear(), usedDate.getUTCMonth(), firstDayOfWeek)).toISOString().slice(0, 10);
        this.lastDays = new Date(Date.UTC(usedDate.getUTCFullYear(), usedDate.getUTCMonth(), firstDayOfWeek + 6)).toISOString().slice(0, 10);
        this.count = Math.round((new Date(usedDate.getUTCFullYear(), usedDate.getUTCMonth(), firstDayOfWeek) - firstDayOfCurrent) / (SECONDS_IN_DAY * 7));
        //arrows date range
        this.firstDay = new Date(Date.UTC(todayDate.getFullYear(), todayDate.getMonth(), currentWeekStartIndex)).toISOString().slice(0, 10);
        this.lastDay = new Date(Date.UTC(todayDate.getFullYear(), todayDate.getMonth(), currentWeekStartIndex + 6)).toISOString().slice(0, 10);
      } else {
        if (used === 'next' || used === 'prev') {
          this.lastUsed = 'arrow';
          this.posY = 0;
          this.clientY = 0;
          if (used === 'next') this.count++;
          else this.count--;
        }

        if (todayDate.getDay() >= START_WEEK_DAY_INDEX) {
          firstDayOfWeek = todayDate.getDate() - todayDate.getDay() + START_WEEK_DAY_INDEX + 7 * this.count;
        } else {
          firstDayOfWeek = todayDate.getDate() - todayDate.getDay() - 7 + START_WEEK_DAY_INDEX + 7 * this.count;
        }

        //Set first day of current week
        this.firstDay = new Date(Date.UTC(todayDate.getFullYear(), todayDate.getMonth(), firstDayOfWeek)).toISOString().slice(0, 10);
        this.lastDay = new Date(Date.UTC(todayDate.getFullYear(), todayDate.getMonth(), firstDayOfWeek + 6)).toISOString().slice(0, 10);
      }
    },

    getFirstAndLastGridYear(date) {
      //set first and last days of week
      let firstWeekDate = new Date(date);
      let lastWeekDate = new Date(date);
      lastWeekDate.setDate(firstWeekDate.getUTCDate() + 6);

      let years = [];
      this.yearsOfGrid = '';

      years.push(firstWeekDate.getUTCFullYear());
      if (firstWeekDate.getUTCFullYear() !== lastWeekDate.getUTCFullYear()) years.push(lastWeekDate.getUTCFullYear());
      this.yearsOfGrid = years.toString();
    },

    async toggleGridSize() {
      this.showSmallGrid = !this.showSmallGrid;
      if (!this.showSmallGrid) {
        this.block_height = 82;
        this.current_scale = 200;
        this.program_height = 42;
      } else {
        this.block_height = 70;
        this.current_scale = 150;
        this.program_height = 36;
      }
      await this.buildGrid();
    },

    getBroadcastingDisallowedEjectionTypeClasses(prop_day) {
      const day = this.gridBroadcastingSettings.find((e) => e.date === prop_day);
      if (!day) return;
      let result = [];
      if ((day.disallow_ejection & 1) === 1) result.push('disallow-ejection-automatic');
      if ((day.disallow_ejection & 2) === 2) result.push('disallow-ejection-low-priority');
      return result.join(' ');
    },

    getClosedTypeClasses(prop_day) {
      const day = this.gridBroadcastingSettings.find((e) => e.date === prop_day);
      if (!day) return;
      let result = [];
      if ((day.is_closed & 1) === 1) result.push('day-closed-agency');
      if ((day.is_closed & 2) === 2) result.push('day-closed-saleshouse');
      return result.join(' ');
    },

    getClosedTypeTitle(prop_day, from) {
      const day = this.gridBroadcastingSettings.find((e) => e.date === prop_day);
      if (!day) return;
      let titleText = '';
      if (from !== 'booking') {
        if (day.is_closed !== 0) {
          titleText += `${this.$t('table.closedFor')}: `;
          if (day.is_closed === 1) titleText += `${this.$t('table.agenciesGenitive')}`;
          else if (day.is_closed === 2) titleText += `${this.$t('table.saleHousesGenitive')}`;
          else if (day.is_closed === 3) titleText += `${this.$t('table.allGenitive')} (${this.$t('sideBar.agencies')} + ${this.$t('sideBar.salehouses')})`;
        }

        //add info for disallowed ejection
        if (day.is_closed !== 0) titleText += ' \n\n';
      }
      if (day.disallow_ejection !== 0) {
        titleText += `${this.$t('table.disallowEjection')}: `;
        if (day.disallow_ejection === 1) titleText += `${this.$t('table.automaticGenitive')}`;
        else if (day.disallow_ejection === 2) titleText += `${this.$t('table.lowPriorityGenitive')}`;
        else if (day.disallow_ejection === 3) titleText += `${this.$t('table.automaticGenitive')}, ${this.$t('table.lowPriorityGenitive')}`;
      }

      return titleText;
    },

    checkIfDayClosed(prop_day) {
      // day.is_closed types, decimal (binary):
      // 0 (00) - open to all;
      // 1 (01) - closed for agencies;
      // 2 (10) - closed for sale houses;
      // 3 (11) - closed for all.
      const day = this.gridBroadcastingSettings.find((e) => e.date === prop_day);
      if (!day || day?.is_closed === 0) return false;
      if (this.currentUserRole === 'Account manager agency' && (day.is_closed === 1 || day.is_closed === 3)) {
        return true;
      } else if (this.currentUserRole === 'Account manager saleshouse' && (day.is_closed === 2 || day.is_closed === 3)) {
        return true;
      } else if (this.currentUserRole !== 'Account manager agency' && this.currentUserRole !== 'Account manager saleshouse') {
        return true;
      } else return false;
    },

    checkIfDayClosedInMarketing(prop_day) {
      const day = this.gridBroadcastingSettings.find((e) => e.date === prop_day);
      if (!day || day?.is_closed !== 3 || this.$store.getters.getIsCurrentUserAdmin || this.$store.getters.getIsCurrentUserMarketingManager) return false;
      // closed day if it's closed for 1 role minimum
      else return true;
    },
  },
};

export default placementGrid;
